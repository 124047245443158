<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-cog"></i>Rule Engine</h2>

        <div id="app" class="container  card col-12" style="background-color: white; padding: 3%" >
            <div class="row p-2">
                <div class="col-12 d-flex justify-content-end">
                    <button class="e-control e-progress-btn e-lib e-btn e-spin-right  "
                            type="button" data-toggle="modal" data-target="#ampleModal"
                            style="color: white; background-color: rgb(56, 56, 56);" >
                        <span class="e-btn-content">Add Type</span>
                    </button>

                    <!-- Modal -->
                    <div class="modal fade " id="ampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Types</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
<!--                                    <div class="form-group p-3">-->
<!--&lt;!&ndash;                                        <label for="recipient-name" class="col-form-label">Type:</label>&ndash;&gt;-->
<!--                                        <input  type="text" class="form-control" id="recipient-name" placeholder="Enter New Type"  v-model ="typeInput">-->

<!--                                        <div class="row p-3">-->
<!--                                            <div class="col-12 d-flex justify-content-center">-->
<!--                                                <button class="e-control e-progress-btn e-lib e-btn e-spin-right  "-->
<!--                                                        type="button" data-toggle="modal" data-target="#ampleModal"-->
<!--                                                        style="color: white; background-color: rgb(56, 56, 56);"  @click="addType">-->
<!--                                                    <span class="e-btn-content">Add Type</span>-->
<!--                                                </button>-->
<!--                                            </div>-->
<!--                                        </div>-->

<!--                                    </div>-->

                                    <ejs-grid ref="typegrid" :dataSource='editdata' :editSettings='editSettings'  height='270' :toolbar='toolbar' @actionComplete="onTypeActionComplete">
                                        <e-columns>
                                            <e-column field='type' headerText='Type'  width=100></e-column>
                                        </e-columns>
                                    </ejs-grid>

                                </div>
                                <div class="modal-footer">
<!--                                    <button type="button" class="btn btn-primary" >Send Request</button>-->
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

                                </div>
                            </div>
                        </div>
                    </div>







                </div>

            </div>


            <ejs-grid ref="editgrid" :dataSource='data' :editSettings='editSettings'  height='270' :toolbar='toolbar' @actionBegin="onActionBegin"   @actionComplete="onActionComplete">
                <e-columns>

                    <e-column field='weekDay' headerText='Week Day'  editType= 'dropdownedit' :edit='weekday' width=100></e-column>
                    <e-column field='name' headerText='Name'  width=100></e-column>
                    <e-column field='shownRate' headerText='Shown Rate' width=100></e-column>
                    <e-column field='needMax' headerText='Need Max'  width=100></e-column>
                    <e-column field='needLimit' headerText='Need Limit' width=100></e-column>
                    <e-column field='type' headerText='Type' editType= 'dropdownedit' :edit='type' width=100></e-column>
                    <e-column field='queueCheckIP' headerText='Que Check Ip ' editType= 'dropdownedit' :edit='queueCheckIp' width=100 ></e-column>
<!--                    <e-column field='reasonForUpdate' headerText='Reason For Update' editType= 'textbox' width=200 :isPrimaryKey='true'></e-column>-->

                </e-columns>
            </ejs-grid>
        </div>
    </div>


</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Page, Toolbar, Edit } from "@syncfusion/ej2-vue-grids";
import {DropDownListPlugin,MultiSelectPlugin} from "@syncfusion/ej2-vue-dropdowns";
// import {DataManager, UrlAdaptor} from '@syncfusion/ej2-data';
import Vue from "vue";
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";
Vue.use(DropDownListPlugin);
Vue.use(MultiSelectPlugin);


let weekday= [{ weekDay: 'Monday', Id: '1' }, { weekDay: 'Tuesday', Id: '2' },
              { weekDay: 'Wednesday', Id: '3' }, { weekDay: 'Thursday', Id: '4' },
              { weekDay: 'Friday', Id: '5' },{ weekDay: 'Saturday', Id: '6' },
              { weekDay: 'Sunday', Id: '7' }];
let type = [];
let queueCheckIp= [{ queueCheckIP: 'Yes', Id: '1' }, { queueCheckIP: 'No', Id: '2' },
    { queueCheckIP: 'Nationwide', Id: '3' }];
// let  dataTypes = [];
export default {
    name: "Rules.Engine",
    props: ["user"],
    // components: {nightlyConf},
    data: function () {
        return {
            selectedRowIndex: -1,
            data: {json:[]},
            editdata: {json:[]},
            oldvalue : {},
            reasonForUpdate:'',
            valueObject:{},
            dataTypes:[],
            typeInput:'',
            editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },
            toolbar: ['Add', 'Edit', 'Delete', 'Update', 'Cancel'],
            weekday: { params: {  dataSource: weekday, fields: { text: 'weekDay', value: 'weekDay' }, allowFiltering: true,}},
            type:{params: { dataSource: type, fields: { text: 'type', value: 'type' }, allowFiltering: true,}},
            item :{},
            fields: { text: 'text', value: 'value' },
            weekdays : ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            queueCheckIp: {params: { dataSource: queueCheckIp, fields: { text: 'queueCheckIP', value: 'queueCheckIP' }, allowFiltering: true,}},
        };
    },
    created: function () {
        this.$root.preloader = false;
        // this.getSettings()
        this.getData()
        this.getType()
        console.log('this.user')
        console.log(this.user)

    },
    methods: {
        async openInputDialog(data) {
            const { value: inputText } = await Swal.fire({
                title: 'Reason For Update?',
                input: 'text',
                inputPlaceholder: 'Reason For Update',
                inputValidator: (value) => {
                    if (!value) {
                        return 'You must enter something!';
                    }
                },
                showCancelButton: true,
                confirmButtonText: 'Submit'
            });

            if (inputText) {
                this.reasonForUpdate = inputText
                this.save(data)
            }
        },
        onActionBegin(args) {
            if (args.requestType === 'beginEdit') {
                const rowInfo = this.$refs.editgrid.getRowInfo(args.row);
                this.selectedRowIndex = rowInfo.rowIndex; // Set the selected index
                console.log('this.selectedRowIndex')
                console.log(this.selectedRowIndex)
               if(this.selectedRowIndex != -1){
                   console.log('old value')
                   console.log(this.oldvalue[this.selectedRowIndex])
                   this.valueObject = this.oldvalue[this.selectedRowIndex]
               }

            }
        },
        onActionComplete(args) {
            console.log(args.requestType)
            if (args.requestType === 'save') {
                const updatedData = args.data; // This contains the updated data object
                this.updatedate(updatedData)
            }else if(args.requestType === 'delete'){
                this.deleteRecord(args.data)
            }
        },
        onTypeActionComplete(args) {
            console.log(args.requestType)
            if (args.requestType === 'save') {
                const updatedData = args.data; // This contains the updated data object
                this.addType(updatedData)
            }else if(args.requestType === 'delete'){
                this.removeType(args.data)
            }
        },
        toolbarClick: function(args = ClickEventArgs) {
            // this.$refs.grid.showSpinner();
            // switch (args.item.text) {
                console.log(args)
            // }
            // switch (args.item.text) {
            //     /* tslint:disable */
            //     case 'Update':
            //         this.updateRecord()
            //         break;
            //     /* tslint:enable */
            //
            //     case 'Delete':
            //         (this.$refs.grid).csvExport(this.getCsvExportProperties());
            //         break;
            // }
        },
        addType(data) {
            console.log(data)
            var request = { apikey: this.$root.apikey, args: data };


            axios({
                url: `${this.$root.serverUrl}/admin/updateType`,
                data: request,
                responseType: "json",
                method: data["_id"] ? "PUT" : "POST",
            }).then(function (resp)  {
                if(resp.data.error){
                    swal({ title: 'Warning', text: resp.data.error, icon: 'error' }).then(() => {

                    })
                }else{
                    swal({ title: 'Success', text: 'Data Saved', icon: 'success' }).then(() => {
                        this.getType()
                        this.$forceUpdate();
                        // this.this.$refs.editgrid.refresh()
                    })
                }

            }.bind(this)).catch(() => {
                swal("Oops!", "There was an issue Resting", "error");
            })
        },
        removeType(data) {


            var request = { apikey: this.$root.apikey, args: data };


            axios({
                url: `${this.$root.serverUrl}/admin/updateType`,
                data: request,
                responseType: "json",
                method: "Delete"
            }).then(function (resp)  {
                if(resp.data.error){
                    swal({ title: 'Warning', text: resp.data.error, icon: 'error' }).then(() => {

                    })
                }else{
                    swal({ title: 'Success', text: 'Data Updated', icon: 'success' }).then(() => {
                        this.getType()
                        this.$forceUpdate();
                        // this.this.$refs.editgrid.refresh()
                    })
                }

            }.bind(this)).catch(() => {
                swal("Oops!", "There was an issue Resting", "error");
            })
        },
        updateRecord() {
            // Your logic to update a record
        },
        cancelEdit() {
            // Your logic to cancel edit mode
        },
        getData(){

            //Load the settings
            var request = { apikey: this.$root.apikey };


            axios
                .get(`${this.$root.serverUrl}/admin/RuleEngine`, { params: request })
                .then(

                    function (resp) {
                        console.log(resp.data.data)
                        this.$root.preloader = false;
                        let count = (resp.data) ? resp.data.Count : 0;
                        let data = (resp.data) ? resp.data.Items : [];
                        this.data = {Items:data,Count:count}
                        this.data.json = data
                        this.oldvalue = data
                        this.$forceUpdate();
                    }.bind(this)
                );
        },
        getType(){
            //Load the settings
            var request = { apikey: this.$root.apikey };


            axios
                .get(`${this.$root.serverUrl}/admin/getTypes`, { params: request })
                .then(

                    function (resp) {
                        console.log(resp.data.data)
                        this.$root.preloader = false;
                        let count = (resp.data) ? resp.data.Count : 0;
                        let data = (resp.data) ? resp.data.Items : [];
                        this.editdata = {Items:data,Count:count}
                        this.editdata.json = data
                        this.dataTypes = data
                        this.type.params.dataSource = data.map(item => {
                            return {
                                Id: item.id.toString(),
                                type: item.type // Set the default value for 'type'
                               // Convert the 'id' to a string and use it as 'Id'
                            };
                        });
                        this.$forceUpdate();
                    }.bind(this)
                );
        },
        updatedate(data){
            if(data["_id"]){
                this.openInputDialog(data)
            }else{
               this.save(data)
            }
        },
        save(data) {
                console.log('mellow fm')
            console.log(data)
            //data.user = this.user
            var request = { apikey: this.$root.apikey, args: data,oldvalue:this.valueObject, user:this.user, reasonForUpdate:this.reasonForUpdate };


            axios({
                url: `${this.$root.serverUrl}/admin/RuleEngine`,
                data: request,
                responseType: "json",
                method: data["_id"] ? "PUT" : "POST",
            }).then(function (resp)  {
                if(resp.data.error){
                    swal({ title: 'Warning', text: resp.data.error, icon: 'error' }).then(() => {

                    })
                }else{
                    swal({ title: 'Success', text: 'Data Saved', icon: 'success' }).then(() => {
                       this.getData()
                        this.$forceUpdate();
                       // this.this.$refs.editgrid.refresh()
                    })
                }

            }.bind(this)).catch(() => {
                swal("Oops!", "There was an issue Resting", "error");
            })
        },
        deleteRecord(data) {


            var request = { apikey: this.$root.apikey, args: data };


            axios({
                url: `${this.$root.serverUrl}/admin/RuleEngine`,
                data: request,
                responseType: "json",
                method: "Delete"
            }).then(function (resp)  {
                if(resp.data.error){
                    swal({ title: 'Warning', text: resp.data.error, icon: 'error' }).then(() => {

                    })
                }else{
                    swal({ title: 'Success', text: 'Data Updated', icon: 'success' }).then(() => {
                        this.getData()
                        this.$forceUpdate();
                        // this.this.$refs.editgrid.refresh()
                    })
                }

            }.bind(this)).catch(() => {
                swal("Oops!", "There was an issue Resting", "error");
            })
        },
    },
    provide: {
        grid: [Page, Edit, Toolbar]
    }
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
</style>
